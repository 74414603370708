import anime from 'animejs'

export default function animation(data, callback) {
  const defaultTime = 1000
  const defaultRelativeTime = '-=1000'

  const timeline = anime.timeline({
    easing: 'easeInOutQuart',
    duration: defaultTime,
    autoplay: false,
    complete: callback
  })

  data.forEach(item =>
    timeline.add(
      {
        targets: item.targets,
        opacity: 1
      },
      item.relative ? defaultRelativeTime : undefined
    )
  )

  return timeline
}
