import React, { useState } from 'react'

import timerDarkSVG from './images/svgs/timer-dark.svg'
import timerSVG from './images/svgs/timer.svg'

const width = 20

export default function Arrow(props) {
  const [iconFill, setIconFill] = useState('#3b4355')
  const [iconTimer, setIconTimer] = useState(timerSVG)

  const handleIconTimerOnMouseEnter = () => {
    setIconFill('#d6bdaa')
    props.hovered && setIconTimer(timerDarkSVG)
  }

  const handleIconTimerOnMouseLeave = () => {
    setIconFill('#3b4355')
    props.hovered && setIconTimer(timerSVG)
  }

  let handleIconMouseEnter
  let handleIconMouseLeave
  let iconHref

  if (props.icon && props.timer) {
    handleIconMouseEnter = handleIconTimerOnMouseEnter
    handleIconMouseLeave = handleIconTimerOnMouseLeave
    iconHref = iconTimer
  }

  return (
    <svg width={width + 2} height={props.length || 100} className={props.className} style={props.style}>
      <defs>
        <marker
          id="tpm-Arrow"
          viewBox="0 0 10 10"
          refX="5"
          refY="5"
          markerWidth="5"
          markerHeight="5"
          orient="auto-start-reverse"
          fill={props.color || '#fff'}
        >
          <path d="M 0 0 L 10 5 L 0 10 z" />
        </marker>
      </defs>

      <line
        x1="10"
        y1={props.length ? props.length - 10 : 90}
        x2="10"
        y2="10"
        fill="none"
        stroke={props.color || '#fff'}
        strokeWidth="3"
        strokeDasharray={props.dashed && '5,5'}
        markerStart="url(#tpm-Arrow)"
        markerEnd={props.end && 'url(#tpm-Arrow)'}
      />

      {props.icon && (
        <g
          onMouseEnter={handleIconMouseEnter}
          onMouseLeave={handleIconMouseLeave}
          data-tip={props.iconDataTip}
          data-for={props.iconDataFor}
        >
          <circle style={{ cursor: 'pointer' }} cx={width / 2 + 1} cy="50" r="10" fill={iconFill} stroke="#fff" />
          <image style={{ cursor: 'pointer' }} x={width / 4 + 1} y="45" width="10" height="10" href={iconHref} />
        </g>
      )}
    </svg>
  )
}
