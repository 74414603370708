import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { Route, HashRouter as Router, Switch, useLocation } from 'react-router-dom'

import Home from '../Home'
import Overview from '../Overview'
import React from 'react'
import TiDB from '../TiDB'
import TiKV from '../TikV'

function Container() {
  return (
    <Router>
      <TransitionWrapper />
    </Router>
  )
}

function TransitionWrapper() {
  const location = useLocation()

  return (
    <TransitionGroup>
      <CSSTransition key={location.pathname} exit={false} classNames="fade" timeout={1000}>
        <Switch location={location}>
          <Route path="/" component={Home} exact />
          <Route path="/overview" component={Overview} />
          <Route path="/tidb" component={TiDB} />
          <Route path="/tikv" component={TiKV} />
        </Switch>
      </CSSTransition>
    </TransitionGroup>
  )
}

export default Container
