import React from 'react'
import ReactTooltip from 'react-tooltip'

const data = [
  {
    id: 'server-entry',
    lines: [
      'Server Entry is the service interface responsible for:',
      '',
      '1. Connection management - each connection corresponds to a session',
      '2. Compatibility with MySQL for user request parsing',
      '3. Limiting request concurrency'
    ]
  },
  {
    id: 'server-entry-session',
    lines: [
      'For OLTP workload, the recommended .999 duration of the query is less than 500ms.',
      '',
      '1. Connection Count: the recommended connection count is less than 500. Pay close attention to whether the number of connections is balanced.',
      '2. Heap Memory: if `local-latch` is enabled, the memory usage should be less than 3GB; if `local-latch` is disabled, the memory usage should be less than 1GB.'
    ]
  },
  {
    id: 'server-entry-token-limit',
    lines: [
      'Control the number of sessions executed concurrently.',
      'It should be larger than the connection count. Otherwise, it may take a significant time to get a token.'
    ]
  },
  {
    id: 'preprocess',
    lines: ['Preprocess is the process to parse SQL texts into AST syntax tree and check the syntax.']
  },
  {
    id: 'preprocess-ep-duration',
    lines: ['The statistics of the parsing time of SQL statements.']
  },
  {
    id: 'pointget',
    lines: [
      'For select/update/delete statements, if the Where condition includes `unique_key/primary_key = xxx`, and the query result only contains one row, we can use this optimization tailored for PointGet.',
      '',
      'The PointGet optimization can avoid the cost from the SQL optimizer and improve the execution efficiency of PointGet queries'
    ]
  },
  {
    id: 'executor',
    lines: [
      'The execution engine at the TiDB layer and the distributed execution engine (Coprocesor) at the TiKV layer.'
    ]
  },
  {
    id: 'executor-tidb-max-chunk-size',
    lines: ['Sets the maximum number of rows in a chunk during the execution process.']
  },
  {
    id: 'sql-optimizer',
    lines: [
      'SQL Optimizer generates an optimal execution plan based on the Abstract Syntax Tree. The optimization procedure involves 2 phases - logic optimization and physical optimization.'
    ]
  },
  {
    id: 'sql-optimizer-ec-duration',
    lines: ['The statistics of the time of compiling an SQL AST to the execution plan.']
  },
  {
    id: 'operator-pushdown',
    lines: ['Selections/Aggregate/TopN/Limit operator are pushed down through coprocessor.']
  },
  {
    id: 'transaction-construction',
    lines: [
      'The transaction processing that invovels two transaction models - optimistic transaction and pessmistic transaction.'
    ]
  },
  {
    id: 'transaction-construction-txn-local-latch',
    lines: [
      'Detects write conflicts within the same TiDB node to reduce retry cost.',
      'The maximum capacity of the latch is 2048000.',
      'You can disable it if you can confirm that there is no potential conflict in your scenario.'
    ]
  },
  {
    id: 'transaction-construction-tidb-constraint-check-in-place',
    lines: [
      'Set whether to do a unique key check each time a row of data is written.',
      'If enabled, it checks whether each inserted row is unique for compatibility.',
      'It is recommended to be disabled for pressure testing.'
    ]
  },
  {
    id: 'distsql',
    lines: [
      'DistSQL sends DAG requests to Coprocessor. DAG requests are conditional or aggregational pushdown read requests for row data or indexes from tables.'
    ]
  },
  {
    id: 'distsql-scan-concurrency',
    lines: [
      'Set the concurrency of the scan operation.',
      'Control the number of workers used to send Coprocessor requests to TiKV Regions concurrently.',
      'Each worker can only send one task to one TiKV Region.'
    ]
  },
  {
    id: 'section-kv',
    lines: [
      'An abstraction of the underlying KV storage engine, mainly including `kv get`, `batch get` and transaction related interfaces.'
    ]
  },
  {
    id: 'kv',
    lines: [
      '1. Lock Resolve OPS - Unlocking frequency caused by write-write/read-write conflicts.',
      'Reference value: less than 500 for both expired and not_expired.',
      '2. KV Backoff OPS - number of times to wait and retry when transactions are blocked by locks or when region routing has been updated.',
      'Reference value: less than 500 for both `txnLockFast` and `txnLock`. Region miss error should be less than 5000 in importing data case.',
      '3. KV Cmd Duration - The delay between sending requests to TiKV and receiving responses.'
    ]
  },
  {
    id: 'tikv-client',
    lines: [
      'A TiKV client is responsible for sending requests to TiKV. There are two types of requests: Raw KV requests and Coprocessor requests.'
    ]
  },
  {
    id: 'tikv-client-max-batch-size',
    lines: [
      'The maximum number of RPC packets sent in batch.',
      'If the value is not 0, the BatchCommands API is used to send requests to TiKV, and the RPC latency can be reduced in the case of high concurrency.',
      'It is recommended that you do not modify this value.'
    ]
  },
  {
    id: 'tikv-client-grpc-connection-count',
    lines: ['The maximum number of connections established with each TiKV.']
  },
  {
    id: 'get-tso',
    lines: [
      'Obtain timestamp of the transaction from PD',
      'Read only transactions only need the timestamp for transaction start',
      'Non-read only transactions need timestamps for both transaction start and commit.'
    ]
  },
  {
    id: 'pd-client',
    lines: ['PD Client sends requests to PD.']
  }
]

export default function GenTooltips() {
  return (
    <>
      {data.map(item => (
        <ReactTooltip className="tpm-tidb-Tooltip" key={item.id} id={item.id} delayHide={200}>
          {item.lines.map((line, index) => (line ? <p key={index}>{line}</p> : <br key={index} />))}
        </ReactTooltip>
      ))}
    </>
  )
}
