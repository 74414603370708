import Block from '../Block'
import React from 'react'
import { withRouter } from 'react-router-dom'

const Home = props => {
  const handleGoTo = name => () => {
    props.history.push(name)
  }

  return (
    <div className="tpm-Home">
      <section className="section">
        <h1 className="title is-2 home-title">TiDB Performance Map</h1>
        <article className="home-descs">
          <p className="is-size-5">What is it?</p>
          <p className="is-size-5">An introduction that clearly shows you the architecture of the TiDB database.</p>
          <p className="is-size-5">
            A flow diagram that details how the modules and components interact with each other.
          </p>
          <p className="is-size-5">
            A manual that gives tuning metrics helping you troubleshoot the bottleneck of the system.
          </p>
          <p className="is-size-5">TiDB Performance Map makes understanding TiDB performance simple.</p>
        </article>
        <article className="home-blocks">
          <Block
            className="block-overview"
            title="TiDB Architecture"
            backgroundColor="#9bc4ca"
            onClick={handleGoTo('/overview')}
          />
          <Block
            className="block-tidb"
            title="TiDB SQL Layer"
            backgroundColor="#5660c0"
            onClick={handleGoTo('/tidb')}
          />
          <Block
            className="block-tikv"
            title="TiKV Storage Layer"
            backgroundColor="#8f7cb8"
            onClick={handleGoTo('/tikv')}
          />
        </article>
      </section>
    </div>
  )
}

export default withRouter(Home)
