import React, { Component } from 'react'
import { addOpacity, removeOpacity } from '../../lib/handleOpacity'
import tidbAnimation, { data } from './animation'

import Arrow from '../Arrow'
import Block from '../Block'
import Replay from '../Replay'
import Section_ from '../Section'
import Tooltips from './Tooltips'
import Up from '../Up'

const Section = Section_('tpm-TiDB-Section')

class TiDB extends Component {
  state = {
    initTooltips: false
  }

  componentDidMount() {
    this.animation = tidbAnimation(() => {
      this.setState({
        initTooltips: true
      })
    })

    if (!localStorage.getItem('tidb-performance-map:tidb')) {
      this.animation.play()
      localStorage.setItem('tidb-performance-map:tidb', 't')
    } else {
      this.setState(
        {
          initTooltips: true
        },
        () => {
          addOpacity(data)
        }
      )
    }
  }

  handleReplay = () => {
    removeOpacity(data)
    this.setState(
      {
        initTooltips: false
      },
      () => {
        this.animation.restart()
      }
    )
  }

  render() {
    return (
      <div className="tpm-Container">
        <div className="container tpm-TiDB">
          <h1 className="title is-2 tidb-title">TiDB SQL Layer</h1>
          <article className="columns is-mobile tidb-map">
            <div className="column is-2 map-left">
              <div className="block-wrapper">
                <Block className="block-client" title="Client" backgroundColor="#9bc4ca" />
              </div>
            </div>
            <div className="column is-2 map-center-left">
              <div className="arrow-wrapper counterclockwise-90 arrow-wrapper-sql">
                <Arrow length={200} />
              </div>
              <div className="arrow-wrapper clockwise-90 arrow-wrapper-results">
                <Arrow length={200} />
              </div>
            </div>
            <div className="column is-4 map-center-right">
              <Section
                className="section-server-entry"
                header="Server Entry"
                width={260}
                height={180}
                titleTooltip
                titleDataTip
                titleDataFor="server-entry"
              >
                <div className="top">
                  <div className="session">
                    <div className="tooltip active-session" data-tip data-for="server-entry-session">
                      Active session
                    </div>
                    <div className="arrow-wrapper session-to-token">
                      <Arrow className="counterclockwise-90" length={80} />
                    </div>
                    <div>token</div>
                  </div>
                  <div className="session-count">{'Active session count <= 1000'}</div>
                </div>
                <div className="bottom">
                  <p>Config:</p>
                  <p className="tooltip" data-tip data-for="server-entry-token-limit">
                    token-limit = 1000
                  </p>
                </div>
              </Section>
              <Section
                className="section-executor"
                header="Executor"
                width={440}
                height={200}
                titleTooltip
                titleDataTip
                titleDataFor="executor"
              >
                <div className="top">
                  <p>Session/Global Variable</p>
                  <p className="tooltip" data-tip data-for="executor-tidb-max-chunk-size">
                    tidb_max_chunk_size
                  </p>
                </div>
                <div className="bottom">
                  <p>Default: 1024</p>
                  <p>Reference values:</p>
                  <p>OLTP App = 32</p>
                  <p>OLAP App = 1024+</p>
                </div>
              </Section>
              <div className="arrow-wrapper counterclockwise-90 arrow-wrapper-get-token">
                <Arrow length={120} />
              </div>
              <div className="arrow-wrapper arrow-wrapper-pointget" data-tip data-for="pointget">
                <Arrow length={80} />
              </div>

              <div className="columns is-mobile in-dashed-box">
                <div className="column">
                  <Arrow className="arrow-to-corprocessor" length={150} />
                  <h2 className="corprocessor is-6">Corprocessor</h2>
                  <Arrow className="above-distsql above-distsql-1" length={300} />
                  <Section
                    className="section-distsql"
                    header="DistSQL"
                    width={250}
                    height={100}
                    titleTooltip
                    titleDataTip
                    titleDataFor="distsql"
                  >
                    <div className="center">
                      <p>Session/Global Variable:</p>
                      <p className="tooltip" data-tip data-for="distsql-scan-concurrency">
                        tidb_distsql_scan_concurrency = 15
                      </p>
                    </div>
                  </Section>
                  <Arrow className="below-distsql" length={100} />
                  <div className="operator-pushdown" data-tip data-for="operator-pushdown">
                    Operator pushdown
                  </div>
                </div>
                <div className="column">
                  <Arrow className="clockwise-180 above-distsql above-distsql-2" length={495} dashed />
                  <Arrow className="clockwise-180 above-tikv-client-1" length={100} dashed />
                </div>
                <div className="column">
                  <Arrow className="clockwise-180 above-kv above-kv-1" length={495} dashed />
                  <Section
                    className="section-kv"
                    header="KV"
                    width={250}
                    height={100}
                    titleTooltip
                    titleDataTip
                    titleDataFor="section-kv"
                  >
                    <div className="center">
                      <p className="tooltip" data-tip data-for="kv">
                        1. kv get / batch get
                      </p>
                      <p className="tooltip" data-tip data-for="kv">
                        2. transaction commit
                      </p>
                    </div>
                  </Section>
                  <Arrow className="clockwise-180 above-tikv-client-3" length={100} dashed />
                </div>
                <div className="column">
                  <Arrow className="above-transaction-construction" />
                  <Section
                    className="section-transaction-construction"
                    header="Transaction Construction"
                    width={400}
                    height={300}
                    titleTooltip
                    titleDataTip
                    titleDataFor="transaction-construction"
                  >
                    <div className="top">
                      <p>
                        Config:{' '}
                        <span className="tooltip" data-tip data-for="transaction-construction-txn-local-latch">
                          txn-local-latch
                        </span>
                      </p>
                      <p>Default value: Enable</p>
                    </div>
                    <div className="center">
                      <p>Session/Global Variable:</p>
                      <p
                        className="tooltip"
                        data-tip
                        data-for="transaction-construction-tidb-constraint-check-in-place"
                      >
                        tidb_constraint_check_in_place
                      </p>
                      <p>Default value: Disable</p>
                    </div>
                    <div className="bottom">
                      <p>1. Transaction Statement Num, should less than 500.</p>
                      <p>
                        2. Transaction Retry Num should be less than 3 times. If it is more than 6 times, means that
                        there are many write-write conflicts.
                      </p>
                    </div>
                  </Section>
                  <Arrow className="above-kv above-kv-2" length={60} />
                  <Arrow className="above-tikv-client-2" length={100} />
                </div>
              </div>

              <Section
                className="section-tikv-client"
                header="TiKV Client"
                width={440}
                height={150}
                titleTooltip
                titleDataTip
                titleDataFor="tikv-client"
              >
                <div className="center">
                  <p>Config: tikv-client</p>
                  <p>
                    <span className="tooltip" data-tip data-for="tikv-client-max-batch-size">
                      max-batch-size
                    </span>{' '}
                    = 15
                  </p>
                  <p>
                    <span className="tooltip" data-tip data-for="tikv-client-grpc-connection-count">
                      grpc-connection-count
                    </span>{' '}
                    = 16
                  </p>
                </div>
              </Section>
              <Section
                className="section-pd-client"
                header="PD Client"
                width={440}
                height={150}
                titleTooltip
                titleDataTip
                titleDataFor="pd-client"
              >
                <div className="center">
                  <p>1. Get TSO from PD-server</p>
                  <p>2. Region Cache</p>
                </div>
              </Section>
              <div className="arrow-wrapper arrow-wrapper-send-request-to-tikv">
                <Arrow />
              </div>
              <div className="arrow-wrapper clockwise-180 arrow-wrapper-return-result-tikv">
                <Arrow dashed />
              </div>
              <div className="arrow-wrapper arrow-wrapper-send-request-to-pd">
                <Arrow />
              </div>
              <div className="arrow-wrapper clockwise-180 arrow-wrapper-return-result-pd">
                <Arrow dashed />
              </div>
            </div>
            <div className="column is-4 map-right">
              <Section
                className="section-preprocess"
                header="Preprocess"
                width={360}
                height={150}
                titleTooltip
                titleDataTip
                titleDataFor="preprocess"
              >
                <div className="top">
                  <p>Grafana Metrics:</p>
                  <p className="tooltip" data-tip data-for="preprocess-ep-duration">
                    Executor/Parse Duration
                  </p>
                  <p>It should be less than 10ms</p>
                </div>
                <div className="bottom">
                  <p>It shows the time to parse a SQL string to an Abstract Syntax Tree</p>
                </div>
              </Section>
              <Section
                className="section-sql-optimizer"
                header="SQL Optimizer"
                width={400}
                height={150}
                titleTooltip
                titleDataTip
                titleDataFor="sql-optimizer"
              >
                <div className="left">
                  <div>Logical optimization</div>
                  <div className="arrow-wrapper l-to-p">
                    <Arrow length={50} />
                  </div>
                  <div>Physical optimization</div>
                </div>
                <div className="right">
                  <div className="top">
                    <p>Grafana Metrics:</p>
                    <p className="tooltip" data-tip data-for="sql-optimizer-ec-duration">
                      Executor/Compile Duration
                    </p>
                  </div>
                  <div className="bottom">
                    <p>It should be less than 30ms</p>
                  </div>
                </div>
              </Section>
              <div className="arrow-wrapper arrow-wrapper-ast">
                <Arrow length={80} />
              </div>
              <div className="arrow-wrapper clockwise-90 arrow-wrapper-physical-plan">
                <Arrow length={160} />
              </div>
              <div className="arrow-wrapper arrow-wrapper-get-tso" data-tip data-for="get-tso">
                <Arrow length={290} />
              </div>
              <div className="arrow-wrapper clockwise-180 arrow-wrapper-get-tso-negative">
                <Arrow length={290} dashed />
              </div>
            </div>
            <div className="dashed-box">
              <h2 className="subtitle is-5 dashed-box-title">Transaction process</h2>
            </div>
          </article>

          {this.state.initTooltips && <Tooltips />}
          <Up />
          <Replay onClick={this.handleReplay} />
        </div>
      </div>
    )
  }
}

export default TiDB
