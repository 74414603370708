import React from 'react'
import ReactTooltip from 'react-tooltip'

const data = [
  {
    id: 'loadBalancerToTiDB',
    lines: [
      'For OLTP workload, the recommended connection count is less than 500.',
      '',
      'Make sure that the connections to the tidb-server instances are balanced.'
    ]
  },
  {
    id: 'tidb',
    lines: [
      '1. Receiving the SQL requests.',
      '2. Processing the SQL related logics.',
      '3. Locating the TiKV address for storing and computing data through Placement Driver (PD).',
      '4. Exchanging data with TiKV.',
      '5. Returning the result.'
    ]
  },
  {
    id: 'tso1',
    lines: [
      'PD TSO Wait Duration: The aggregated latency of TiDB obtaining timestamps from PD.',
      '',
      'This value will be high if the tidb-server has a very high workload.'
    ]
  },
  {
    id: 'tso2',
    lines: [
      'PD TSO RPC Duration: The RPC latency of TiDB obtaining timestamps from PD.',
      '',
      "It's mostly related to the latency between TiDB and PD.",
      '',
      'Reference value: less than 30ms within the same data center.'
    ]
  },
  {
    id: 'pd',
    lines: [
      '1. Storing the metadata of the cluster.',
      '2. Scheduling and load balancing regions in the TiKV cluster, including but not limited to data migration and Raft group leader transfer.',
      '3. Allocating the transaction ID that is globally unique and monotonic increasing.'
    ]
  },
  {
    id: 'tikv',
    lines: [
      '1. Region is the basic unit to store data, and also the basic unit for scheduling the workload.',
      '2. Each Region stores the data for a particular Key Range.',
      '3. There are multiple Regions in each TiKV node, so TiKV uses the Raft protocol for replication to ensure data consistency and disaster recovery.',
      '4. The replicas of the same Region on different nodes compose a Raft Group.',
      '5. The workload of the data among different TiKV nodes is scheduled by PD.'
    ]
  }
]

export default function GenTooltips() {
  return (
    <>
      {data.map(item => (
        <ReactTooltip className="tpm-Tooltip" key={item.id} id={item.id} delayHide={200}>
          {item.lines.map((line, index) => (line ? <p key={index}>{line}</p> : <br key={index} />))}
        </ReactTooltip>
      ))}
    </>
  )
}
