import React from 'react'
import PropTypes from 'prop-types'

const Section = topClassName => props => (
  <article
    className={`${topClassName}${props.className ? ' ' + props.className : ''}`}
    style={{ ...props.style, width: props.width, height: props.height }}
  >
    <h2
      className={`subtitle is-6 section-title${props.titleTooltip ? ' tooltip' : ''}`}
      data-tip={props.titleDataTip}
      data-for={props.titleDataFor}
    >
      {props.header}
    </h2>
    <div className="section-content">{props.children}</div>
  </article>
)

Section.propTypes = {
  topClassName: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  width: PropTypes.number,
  height: PropTypes.number,
  header: PropTypes.string,
  children: PropTypes.element,
  titleTooltip: PropTypes.bool,
  titleDataTip: PropTypes.bool,
  titleDataFor: PropTypes.string
}

export default Section
