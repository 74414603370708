import animation from '../../lib/animation'

export const data = [
  { targets: '.arrow-wrapper-external-request' },
  { targets: '.block-grpc' },
  { targets: '.arrow-wrapper-write-request' },
  { targets: '.section-scheduler-pool' },
  { targets: '.arrow-wrapper-latch-got' },
  { targets: '.section-raftstore-pool' },
  { targets: '.arrow-wrapper-append-log' },
  { targets: '.block-wrapper-raft-rocksdb' },
  { targets: '.arrow-wrapper-after-block-raft-rocksdb' },
  { targets: '.section-apply-thread-pool' },
  { targets: '.arrow-wrapper-apply-log' },
  { targets: '.section-kv-rocksdb' },

  { targets: '.arrow-wrapper-async-snapshot' },
  { targets: '.arrow-wrapper-bottom-async-snapshot', relative: true },

  { targets: '.section-coprocessor-readpool' },
  { targets: '.block-storage-readpool', relative: true },

  { targets: '.arrow-wrapper-replicate-to' },
  { targets: '.arrow-wrapper-push-down-read' },
  { targets: '.arrow-wrapper-kv-read' },
  { targets: '.arrow-wrapper-return-result' }
]

export default function tidbAnimation(callback) {
  return animation(data, callback)
}
