import animation from '../../lib/animation'

export const data = [
  { targets: '.client' },
  { targets: '.client-to-load-balancer .right' },
  { targets: '.client-to-load-balancer .right-arrow', relative: true },
  { targets: '.load-balancer' },
  { targets: '.load-balancer-to-tidb' },
  { targets: '.tidb-layer' },

  { targets: '.tidb-to-tikv' },
  { targets: '.tidb-to-pd', relative: true },

  { targets: '.tikv-layer' },
  { targets: '.pd-layer', relative: true },

  { targets: '.tikv-to-pd' },

  { targets: '.client-to-load-balancer .left' },
  { targets: '.client-to-load-balancer .left-arrow', relative: true }
]

export default function tidbAnimation(callback) {
  return animation(data, callback)
}
