import React from 'react'
import { useHistory } from 'react-router-dom'

const Up = () => {
  const history = useHistory()

  const goBack = () => history.goBack()

  return (
    <div className="tpm-Up" onClick={goBack}>
      Up
    </div>
  )
}

export default Up
