import React from 'react'
import PropTypes from 'prop-types'

const Block = props => (
  <article
    className={`tpm-Block${props.hovered ? ' hovered' : ''}${props.className ? ' ' + props.className : ''}`}
    style={{
      backgroundColor: props.backgroundColor || '#000',
      ...props.style
    }}
    onClick={props.onClick}
  >
    <h3
      className={`title is-5${props.titleTooltip ? ' tooltip' : ''}`}
      data-tip={props.titleDataTip}
      data-for={props.titleDataFor}
    >
      {props.title}
    </h3>
    {props.subtitle && <h4 className="subtitle is-6">{props.subtitle}</h4>}
  </article>
)

Block.propTypes = {
  className: PropTypes.string,
  style: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  backgroundColor: PropTypes.string,
  hovered: PropTypes.bool,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  onClick: PropTypes.func,
  titleTooltip: PropTypes.bool,
  titleDataTip: PropTypes.bool,
  titleDataFor: PropTypes.string
}

export default Block
