import React from 'react'
import ReactTooltip from 'react-tooltip'

const data = [
  {
    id: 'grpc',
    lines: [
      'The CPU usage of the gRPC thread pool should be less than grpc-concurrency * 90%.',
      '',
      '[server]',
      'grpc-concurrency = 4'
    ]
  },
  {
    id: 'scheduler-pool',
    lines: [
      'In TiKV,  concurrency control over transaction related write requests is handled by Scheduler, which uses latch to ensure that the a key won’t be altered by multiple threads at the same time. Scheduler distributes a write request to the scheduler worker pool for restraint check before the write applies in Raft.',
      '',
      'The CPU usage of scheduler pool should be less than scheduler-worker-pool-size * 90%.',
      '',
      'scheduler-worker-pool-size = 4'
    ]
  },
  {
    id: 'scheduler-pool-1',
    lines: [
      'The recommended .99 latch wait duration should be less than 20ms.',
      'If the latch wait duration is high, it indicates write conflicts. This may happen when there are too many keys written in TiKV concurrently. Consider increasing the latch limit for such scenarios.',
      '',
      'The default value of latch is:',
      '',
      '[storage]',
      'scheduler-concurrency = 2048000'
    ]
  },
  {
    id: 'async-write',
    lines: [
      'The async-write duration is the raft write duration. It consists of:',
      '',
      '1. Propose Wait Duration',
      '2. Append Log Duration',
      '3. Commit Log Duration',
      '4. Apply Wait Duration',
      '5. Apply Log Duration',
      '',
      'The recommended .99 async-write duration should be less than 200ms.'
    ]
  },
  {
    id: 'raftstore-pool',
    lines: [
      'Raftstore is the core driving component for the Raft state machine. Both read and write requests rely on Raftstore to turn in to Raft entries, which are persisted to Raft RocksDB. A Raftstore pool is formed by Raft state machines of multiple Regions driven by multiple threads.',
      '',
      'The CPU usage of raftstore should be less than store-pool-size * 85%.',
      '',
      '[raftstore]',
      'store-pool-size = 2'
    ]
  },
  {
    id: 'raftstore-pool-1',
    lines: [
      'The recommended propose wait duration should be less than 50ms.',
      'If the propose wait duration is high, it means the raftstore is busy. It may because:',
      '',
      '1. Append raft log is slow;',
      '2. The CPU of raftstore is high;',
      '3. Too many regions, long raftstore event duration (in v2.1.x).'
    ]
  },
  {
    id: 'append-log',
    lines: ['The recommended .99 append log duration should be less than 50ms.']
  },
  {
    id: 'apply-thread-pool',
    lines: [
      'When a consensus is reached for read/write requests via Raft, the requests will be distributed to Apply Thread Pool for read/write operations on KV RocksDB.'
    ]
  },
  {
    id: 'apply-thread-pool-1',
    lines: [
      'The recommended .99 apply wait duration should be less than 100ms.',
      'If the apply wait duration is high it means the apply pool is busy or the write db duration is high.',
      'The CPU usage of the apply pool should be less than apply-pool-size * 90%.',
      '',
      '[raftstore]',
      'apply-pool-size = 2'
    ]
  },
  {
    id: 'apply-log',
    lines: ['The recommended .99 apply log duration should be less than 100ms.']
  },
  {
    id: 'coprocessor-readpool',
    lines: [
      'Most SQL read requests in TiDB are processed by the Coprocessor Readpool, which handles push-down calculations from TiDB.',
      '',
      '[readpool.coprocessor]',
      'high-concurrency = 8',
      'normal-concurrency = 8',
      'low-concurrency = 8',
      '',
      'The CPU usage should be less than concurrency * 90%.'
    ]
  },
  {
    id: 'coprocessor-wait',
    lines: [
      'The recommended .99 coprocessor wait duration should be less than 50ms.',
      'If it is high, it means the coprocessor is busy.'
    ]
  },
  {
    id: 'storage-readpool',
    lines: [
      'Point lookups or read requests from raw APIs are processed via the Storage Readpool thread pool.',
      '',
      '[readpool.storage]',
      'high-concurrency = 4',
      'normal-concurrency = 4',
      'low-concurrency = 4',
      '',
      'The CPU usage should be less than concurrency * 90%.'
    ]
  },
  {
    id: 'raft-rocksdb',
    lines: ['Raft RocksDB is the RocksDB instance in TiKV responsible for Raft entry storage.']
  },
  {
    id: 'kv-rocksdb',
    lines: ['KV RocksDB is the RocksDB instance in TiKV responsible for actual data storage']
  },
  {
    id: 'async-snapshot',
    lines: [
      'Before processing read/write requests, TiKV asynchronously verifies via Raftstore if the read permission is valid, and get the RocksDB snapshot.'
    ]
  }
]

export default function GenTooltips() {
  return (
    <>
      {data.map(item => (
        <ReactTooltip className="tpm-tikv-Tooltip" key={item.id} id={item.id} delayHide={200}>
          {item.lines.map((line, index) => (line ? <p key={index}>{line}</p> : <br key={index} />))}
        </ReactTooltip>
      ))}
    </>
  )
}
