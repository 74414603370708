import React, { Component } from 'react'
import { addOpacity, removeOpacity } from '../../lib/handleOpacity'
import overviewAnimation, { data } from './animation'

import Arrow from '../Arrow'
import Block from '../Block'
import Replay from '../Replay'
import Tooltips from './Tooltips'
import Up from '../Up'
import { withRouter } from 'react-router-dom'

class Overview extends Component {
  constructor(props) {
    super(props)

    this.state = {
      initTooltips: false
    }
    this.overviewMap = React.createRef()
  }

  componentDidMount() {
    this.animation = overviewAnimation(() => {
      this.setState({
        initTooltips: true
      })
    })

    if (!localStorage.getItem('tidb-performance-map:overview')) {
      this.animation.play()
      localStorage.setItem('tidb-performance-map:overview', 't')
    } else {
      this.setState(
        {
          initTooltips: true
        },
        () => {
          addOpacity(data)
        }
      )
    }
  }

  handleReplay = () => {
    removeOpacity(data)
    this.setState(
      {
        initTooltips: false
      },
      () => {
        this.animation.restart()
      }
    )
  }

  handleGoTo = name => () => {
    this.props.history.push(name)
  }

  render() {
    return (
      <div className="tpm-Container">
        <div className="container tpm-Overview">
          <article className="overview-titles">
            <h1 className="title is-2 overview-title">TiDB Architecture</h1>
          </article>
          <article className="columns is-mobile overview-map" ref={this.overviewMap}>
            <div className="column is-7 map-left">
              <div className="block-wrapper client client-offset-for-tidb">
                <Block title="Client" backgroundColor="#9bc4ca" />
              </div>
              <div className="client-to-load-balancer">
                <h4 className="subtitle is-6 left">Returns the result</h4>
                <Arrow className="arrow clockwise-180 left-arrow" />
                <Arrow className="arrow right-arrow" />
                <h4 className="subtitle is-6 right">Sends the SQL requests</h4>
              </div>
              <div className="block-wrapper load-balancer load-balancer-offset-for-tidb">
                <Block title="Load Balancer" subtitle="(optional)" backgroundColor="#d69b5b" />
              </div>
              <div className="load-balancer-to-tidb">
                <Arrow className="arrow clockwise-45" end />
                <Arrow className="arrow counterclockwise-45" end />
                <div className="connection tooltip" data-tip data-for="loadBalancerToTiDB">
                  connection
                </div>
              </div>
              <div className="tidb-layer">
                <h4 className="subtitle is-6 tooltip" data-tip data-for="tidb">
                  Stateless SQL layer
                </h4>
                <Block
                  className="block-tidb"
                  title="TiDB"
                  backgroundColor="#504eaf"
                  onClick={this.handleGoTo('/tidb')}
                  hovered
                />
                <Block className="block-tidb" title="TiDB" backgroundColor="#5660c0" />
                <div className="tidb-to-pd">
                  <div className="arrow-wrapper arrow-wrapper-1">
                    <Arrow
                      className="arrow counterclockwise-90"
                      icon
                      timer
                      hovered
                      end
                      iconDataTip
                      iconDataFor="tso1"
                    />
                  </div>
                  <div className="arrow-wrapper">
                    <Arrow
                      className="arrow counterclockwise-90"
                      icon
                      timer
                      hovered
                      end
                      iconDataTip
                      iconDataFor="tso2"
                    />
                  </div>
                </div>
              </div>
              <div className="tidb-to-tikv">
                <Arrow className="arrow arrow-1 clockwise-45" end />
                <Arrow className="arrow arrow-2 counterclockwise-45" end />
                <Arrow className="arrow arrow-3 clockwise-45" end />
                <Arrow className="arrow arrow-4 counterclockwise-45" end />
                <Arrow className="arrow counterclockwise-70 special-arrow" length={200} end />
              </div>
              <div className="tikv-layer">
                <div className="descs">
                  <h4 className="subtitle is-6 tooltip" data-tip data-for="tikv">
                    Distributed
                  </h4>
                  <h4 className="subtitle is-6 tooltip" data-tip data-for="tikv">
                    Storage layer
                  </h4>
                </div>
                <Block
                  className="block-tikv"
                  title="TiKV"
                  backgroundColor="#615086"
                  onClick={this.handleGoTo('/tikv')}
                  hovered
                />
                <Block className="block-tikv" title="TiKV" backgroundColor="#8f7cb8" />
                <Block className="block-tikv" title="TiKV" backgroundColor="#8f7cb8" />
                <div className="curve" alt="curve" />
                <div className="curve" alt="curve" />
                <div className="tikv-to-pd">
                  <div className="arrow-wrapper arrow-wrapper-1">
                    <Arrow className="arrow clockwise-90" end />
                  </div>
                  <div className="arrow-wrapper arrow-wrapper-2">
                    <Arrow className="arrow clockwise-90" end />
                  </div>
                </div>
              </div>
            </div>
            <div className="column is-2 map-center"></div>
            <div className="column is-3 map-right">
              <div className="pd-layer">
                <div className="pds">
                  <Block className="block-pd" title="PD" backgroundColor="#8b7883" />
                  <Block className="block-pd" title="PD" backgroundColor="#8b7883" />
                  <Block className="block-pd" title="PD" backgroundColor="#e8aab4" hovered />
                </div>
                <div className="pd-desc-wrapper">
                  <h4 className="subtitle is-6 pd-desc tooltip" data-tip data-for="pd">
                    PD cluster
                  </h4>
                </div>
              </div>
            </div>
          </article>

          {this.state.initTooltips && <Tooltips />}
          <Up />
          <Replay onClick={this.handleReplay} />
        </div>
      </div>
    )
  }
}

export default withRouter(Overview)
