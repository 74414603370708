import Container from './components/Container'
import React from 'react'

function App() {
  return (
    <div className="tpm-App">
      <Container />
    </div>
  )
}

export default App
