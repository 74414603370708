import React, { Component } from 'react'
import { addOpacity, removeOpacity } from '../../lib/handleOpacity'
import tikvAnimation, { data } from './animation'

import Arrow from '../Arrow'
import Block from '../Block'
import ChannelSVG from './images/channel.svg'
import LatchSVG from './images/latch.svg'
import Replay from '../Replay'
import Section_ from '../Section'
import Tooltips from './Tooltips'
import Up from '../Up'

const Section = Section_('tpm-TiKV-Section')

class TiKV extends Component {
  state = {
    initTooltips: false
  }

  componentDidMount() {
    this.animation = tikvAnimation(() => {
      this.setState({
        initTooltips: true
      })
    })

    if (!localStorage.getItem('tidb-performance-map:tikv')) {
      this.animation.play()
      localStorage.setItem('tidb-performance-map:tikv', 't')
    } else {
      this.setState(
        {
          initTooltips: true
        },
        () => {
          addOpacity(data)
        }
      )
    }
  }

  handleReplay = () => {
    removeOpacity(data)
    this.setState(
      {
        initTooltips: false
      },
      () => {
        this.animation.restart()
      }
    )
  }

  render() {
    return (
      <div className="tpm-Container">
        <div className="container tpm-TiKV">
          <h1 className="title is-2 tikv-title">TiKV Storage Layer</h1>
          <article className="columns is-mobile tikv-map">
            <div className="column is-2 map-left">
              <div className="block-wrapper">
                <Block
                  className="block-grpc"
                  title="gRPC"
                  backgroundColor="#8f7cbb"
                  titleTooltip
                  titleDataTip
                  titleDataFor="grpc"
                />
                <div className="arrow-wrapper counterclockwise-90 arrow-wrapper-external-request">
                  <Arrow length={150} />
                </div>
                <div className="arrow-wrapper clockwise-90 arrow-wrapper-return-result">
                  <Arrow length={150} dashed />
                </div>
              </div>
            </div>
            <div className="column is-6 map-center">
              <div className="columns">
                <div className="column">
                  <div className="arrow-wrapper counterclockwise-90 arrow-wrapper-write-request">
                    <Arrow />
                  </div>
                </div>
                <div className="column">
                  <Section
                    className="section-scheduler-pool"
                    header="Scheduler Worker Pool"
                    width={200}
                    height={180}
                    titleTooltip
                    titleDataTip
                    titleDataFor="scheduler-pool"
                  >
                    <div className="center">
                      <div className="top title is-5">
                        <img src={LatchSVG} alt="latch" /> Latch
                      </div>
                      <div className="bottom tooltip" data-tip data-for="scheduler-pool-1">
                        latch wait duration
                      </div>
                    </div>
                  </Section>
                </div>
                <div className="column">
                  <div className="arrow-wrapper counterclockwise-90 arrow-wrapper-latch-got">
                    <Arrow />
                    <div className="async-write tooltip" data-tip data-for="async-write">
                      Async write
                    </div>
                  </div>
                </div>
                <div className="column">
                  <Section
                    className="section-raftstore-pool"
                    header="Raftstore Pool"
                    width={200}
                    height={180}
                    titleTooltip
                    titleDataTip
                    titleDataFor="raftstore-pool"
                  >
                    <div className="center">
                      <div className="top title is-5">
                        <img src={ChannelSVG} alt="channel" /> Channel
                      </div>
                      <div className="bottom tooltip" data-tip data-for="raftstore-pool-1">
                        propose wait duration
                      </div>
                    </div>
                  </Section>
                </div>
              </div>
              <div className="arrow-wrapper clockwise-90 arrow-wrapper-replicate-to">
                <Arrow length={590} />
              </div>
              <div className="coprocessor-readpool">
                <div className="arrow-wrapper clockwise-90 arrow-wrapper-push-down-read">
                  <Arrow length={150} dashed />
                  <div className="coprocessor-wait tooltip" data-tip data-for="coprocessor-wait">
                    Coprocessor wait
                  </div>
                </div>
                <Section
                  className="section-coprocessor-readpool"
                  header="Coprocessor Readpool"
                  height={150}
                  titleTooltip
                  titleDataTip
                  titleDataFor="coprocessor-readpool"
                >
                  <div className="center">
                    <div className="bottom">Coprocessor handle duration</div>
                  </div>
                </Section>
                <div
                  className="arrow-wrapper clockwise-90 arrow-wrapper-async-snapshot"
                  data-tip
                  data-for="async-snapshot"
                >
                  <Arrow dashed />
                </div>
              </div>
              <div className="storage-readpool">
                <div className="arrow-wrapper clockwise-90 arrow-wrapper-kv-read">
                  <Arrow length={150} dashed />
                </div>
                <Block
                  className="block-storage-readpool"
                  title="Storage Readpool"
                  backgroundColor="#8f7cbb"
                  titleTooltip
                  titleDataTip
                  titleDataFor="storage-readpool"
                />
                <div className="arrow-wrapper clockwise-90 arrow-wrapper-bottom-async-snapshot">
                  <Arrow dashed />
                </div>
              </div>
            </div>
            <div className="column is-6 map-right">
              <div className="columns">
                <div className="column">
                  <div className="arrow-wrapper counterclockwise-90 arrow-wrapper-append-log">
                    <Arrow />
                    <div className="append-log tooltip" data-tip data-for="append-log">
                      Append log
                    </div>
                  </div>
                </div>
                <div className="column">
                  <div className="block-wrapper block-wrapper-raft-rocksdb">
                    <Block
                      title="Raft RocksDB"
                      subtitle="(store raft-log)"
                      backgroundColor="#8f7cbb"
                      titleTooltip
                      titleDataTip
                      titleDataFor="raft-rocksdb"
                    />
                  </div>
                </div>
                <div className="column">
                  <div className="arrow-wrapper counterclockwise-90 arrow-wrapper-after-block-raft-rocksdb">
                    <Arrow />
                  </div>
                </div>
                <div className="column">
                  <Section
                    className="section-apply-thread-pool"
                    header="Apply Thread Pool"
                    width={200}
                    height={180}
                    titleTooltip
                    titleDataTip
                    titleDataFor="apply-thread-pool"
                  >
                    <div className="center">
                      <div className="top title is-5">
                        <img src={ChannelSVG} alt="channel" /> Channel
                      </div>
                      <div className="bottom tooltip" data-tip data-for="apply-thread-pool-1">
                        apply wait duration
                      </div>
                    </div>
                  </Section>
                </div>
              </div>
              <div className="arrow-wrapper arrow-wrapper-apply-log">
                <Arrow length={120} />
                <div className="apply-log tooltip" data-tip data-for="apply-log">
                  Apply log
                </div>
              </div>
              <Section
                className="section-kv-rocksdb"
                header="KV RocksDB"
                width={600}
                height={560}
                titleTooltip
                titleDataTip
                titleDataFor="kv-rocksdb"
              >
                <div className="columns">
                  <div className="column is-6 section-left">
                    <div className="top">
                      <div className="left">
                        <div className="arrow-wrapper clockwise-90 arrow-wrapper-read-path-1">
                          <Arrow dashed />
                        </div>
                      </div>
                      <div className="right">
                        <Arrow length={50} />
                        <Block title="WAL" backgroundColor="#BBAFD5" />
                        <Arrow length={50} />
                        <Block className="block-mem-table" title="mem-table" backgroundColor="#BBAFD5" />
                      </div>
                    </div>
                    <div className="bottom">
                      <div className="left">
                        <div className="arrow-wrapper clockwise-90 arrow-wrapper-read-path-2">
                          <Arrow dashed />
                        </div>
                      </div>
                      <div className="right">
                        <Block title="block-cache" backgroundColor="#BBAFD5" />
                        <div className="arrow-wrapper clockwise-180 arrow-wrapper-block-cache">
                          <Arrow length={50} dashed />
                        </div>
                        <Block title="System page cache" backgroundColor="#BBAFD5" />
                      </div>
                    </div>
                  </div>
                  <div className="column is-6 section-right">
                    <div className="top">
                      <div className="left">
                        <Arrow className="arrow counterclockwise-90" />
                      </div>
                      <div className="right">
                        <div>
                          <div className="compaction is-size-5">Compaction</div>
                          <div className="ssts">
                            <div>
                              <div className="is-size-6">SST</div>
                              <div className="is-size-6">SST</div>
                              <div className="is-size-6">SST</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bottom">
                      <div className="arrow-wrapper clockwise-45">
                        <Arrow length={260} dashed />
                      </div>
                    </div>
                  </div>
                </div>
              </Section>
            </div>
          </article>

          {this.state.initTooltips && <Tooltips />}
          <Up />
          <Replay onClick={this.handleReplay} />
        </div>
      </div>
    )
  }
}

export default TiKV
