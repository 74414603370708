import animation from '../../lib/animation'

export const data = [
  { targets: '.block-client' },
  { targets: '.arrow-wrapper-sql' },
  { targets: '.section-server-entry' },
  { targets: '.arrow-wrapper-get-token' },
  { targets: '.section-preprocess' },

  { targets: '.arrow-wrapper-pointget' },
  { targets: '.arrow-wrapper-ast', relative: true },

  { targets: '.section-executor' },
  { targets: '.arrow-wrapper-physical-plan', relative: true },
  { targets: '.section-sql-optimizer', relative: true },

  { targets: '.dashed-box' },

  { targets: '.arrow-to-corprocessor' },
  { targets: '.above-transaction-construction', relative: true },
  { targets: '.corprocessor', relative: true },
  { targets: '.section-transaction-construction', relative: true },
  { targets: '.above-distsql-1', relative: true },
  { targets: '.operator-pushdown', relative: true },
  { targets: '.section-distsql', relative: true },
  { targets: '.arrow-wrapper-get-tso', relative: true },
  { targets: '.arrow-wrapper-get-tso-negative', relative: true },
  { targets: '.section-pd-client', relative: true },
  { targets: '.above-kv-2', relative: true },
  { targets: '.section-kv', relative: true },
  { targets: '.below-distsql', relative: true },
  { targets: '.section-tikv-client ', relative: true },
  { targets: '.above-tikv-client-1', relative: true },
  { targets: '.above-tikv-client-2', relative: true },
  { targets: '.above-tikv-client-3', relative: true },
  { targets: '.above-distsql-2', relative: true },
  { targets: '.above-kv-1', relative: true },
  { targets: '.arrow-wrapper-send-request-to-tikv', relative: true },
  { targets: '.arrow-wrapper-return-result-tikv', relative: true },
  { targets: '.arrow-wrapper-send-request-to-pd', relative: true },
  { targets: '.arrow-wrapper-return-result-pd', relative: true },

  { targets: '.arrow-wrapper-results' }
]

export default function tidbAnimation(callback) {
  return animation(data, callback)
}
